import { useStaticQuery, graphql } from "gatsby"

const getCategories = graphql`
  query Categories {
    allFile(
      filter: {
        extension: { eq: "mdx" }
        sourceInstanceName: { eq: "articles" }
      }
    ) {
      group(field: childMdx___frontmatter___categorySlug) {
        nodes {
          childMdx {
            frontmatter {
              category
              title
              slug
              categorySlug
            }
          }
        }
        totalCount
      }
    }
  }
`

export const useCategories = () => {
  const data = useStaticQuery(getCategories)
  console.log("category list", data)
  const {
    allFile: { group: categories },
  } = data

  return categories
}

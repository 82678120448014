import React from "react"

import Img from "gatsby-image"
import { Link } from "gatsby"

import { featuredImage } from "./featured-image.module.scss"

const PostImage = ({ image, target, caption }) => {
  if (target) {
    return (
      <figure className={featuredImage}>
        <Link to={target}>
          <Img fluid={image} alt={caption} />
        </Link>
      </figure>
    )
  }

  return (
    <figure className={featuredImage}>
      <Img fluid={image} alt={caption} />
    </figure>
  )
}

export default PostImage

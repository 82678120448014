import React from "react"
import { Link } from "gatsby"
import { useCategories } from "../../hooks/use-categories"

// import { categoryList } from "./category-list.module.scss"

const CategoryList = () => {
  const categories = useCategories()
  // console.log('categories', categories)

  const normalizedCategories = categories.map((o) => ({
    slug:
      o.nodes && o.nodes.length
        ? o.nodes[0].childMdx.frontmatter.categorySlug
        : null,
    count: o.totalCount,
    name:
      o.nodes && o.nodes.length
        ? o.nodes[0].childMdx.frontmatter.category
        : null,
    posts: o.nodes && o.nodes.length ? o.nodes : [],
  }))

  return (
    <div data-testid="category-list">
      <nav>
        <ul>
          {normalizedCategories.map((category) => (
            <li key={category.slug}>
              <Link to={`/category/${category.slug}`}>
                {category.name} ({category.count})
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  )
}

export default CategoryList

import { useStaticQuery, graphql } from "gatsby"

const getArticles = graphql`
  query {
    allFile(
      filter: {
        sourceInstanceName: { eq: "articles" }
        childMdx: { frontmatter: { status: { eq: "published" } } }
      }
      sort: { fields: childMdx___frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          childMdx {
            excerpt(pruneLength: 500)
            frontmatter {
              author
              bannerCaption
              category
              categorySlug
              date
              keywords
              slug
              status
              title
              banner {
                id
                childImageSharp {
                  id
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const useArticles = () => {
  const {
    allFile: { edges: articles },
  } = useStaticQuery(getArticles)

  return articles
}

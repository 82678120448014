import React from "react"
import { Link } from "gatsby"

import './post-meta.scss'

const PostMeta = ({ date, category, categorySlug, author }) => (
  <ul className="post-meta">
    <li className="post-meta-item">
      <span className="post-meta-label">Published On:</span> {date}
    </li>
    <li className="post-meta-item">
      <span className="post-meta-label">Category:</span>{" "}
      <Link to={`/category/${categorySlug}`}>{category}</Link>
    </li>
    <li className="post-meta-item">
      <span className="post-meta-label">Author:</span> {author}
    </li>
  </ul>
)

export default PostMeta

import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"

import PostImage from "../featured-image/featured-image"
import PostMeta from "../post-meta/post-meta";

import "./post-listing.scss"

// TODO: add support for video posts

const PostListing = ({ excerpt, frontmatter }) => {
  const { author, title, date, category, categorySlug, slug, banner, bannerCaption } = frontmatter

  const featuredImage =
    banner && banner.childImageSharp ? banner.childImageSharp.fluid : null

  return (
    <div className="post-listing">
      <div className="article-block-details">
        <h2 className="article-title">
          <Link to={`/articles/${slug}`}>{title}</Link>
        </h2>
        <PostMeta
          date={date}
          author={author}
          category={category}
          categorySlug={categorySlug}
        />
        {featuredImage && (
          <PostImage
            image={featuredImage}
            target={`/articles/${slug}`}
            caption={bannerCaption}
          />
        )}
        <div className="post-listing-excerpt"><p>{excerpt}</p></div>
        <Link to={`/articles/${slug}`}>Read More</Link>
      </div>
    </div>
  )
}

PostListing.propTypes = {
  frontmatter: PropTypes.object.isRequired,
}

export default PostListing

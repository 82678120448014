import React from "react"

import CategoryList from "../category-list/category-list"
import LatestArticles from "../latest-articles/latest-articles"

import "./sidebar-main.scss"

const Sidebar = ({ children }) => {
  if (children) {
    return (
      <section className="sidebar-main">
        <div className="sidebar-inner">{children}</div>
      </section>
    )
  } else {
    return (
      <section className="sidebar-main">
        <div className="sidebar-inner">
          <div className="sidebar-item">
            <h2 className="sidebar-heading">Categories</h2>
            <CategoryList />
          </div>
          <div className="sidebar-item">
            <h2 className="sidebar-heading">Recent Articles</h2>
            <LatestArticles />
          </div>
        </div>
      </section>
    )
  }
}

export default Sidebar

import React from "react"
import { graphql } from "gatsby"

import LayoutMain from "../components/layout-main/layout-main"
import SeoHeaders from "../components/seo-headers/seo-headers"
import HeroUnit from "../components/hero-unit/hero-unit"
import PostListing from "../components/post-listing/post-listing"
import SidebarMain from "../components/sidebar-main/sidebar-main"

export default function Template({ data, pageContext }) {
  const {
    allFile: { nodes: posts },
  } = data
  const { category } = pageContext
  // console.log('category data', data)

  const normalizedPosts = posts.map((o) => {
    return {
      node: { ...o },
    }
  })

  return (
    <LayoutMain className="page-articles">
      <SeoHeaders title={category} keywords={[`blog, articles`]} />
      <HeroUnit pageTitle={category}>
        <p>We are a full service digital agency.</p>
      </HeroUnit>
      <section>
        <div className="content-section">
          <div className="content-section-inner">
            <div className="content-holder">
              <ul className="post-feed">
                {normalizedPosts.map(
                  ({
                    node: {
                      id,
                      childMdx: { frontmatter, excerpt },
                    },
                  }) => {
                    return (
                      <li key={id}>
                        <PostListing
                          frontmatter={frontmatter}
                          excerpt={excerpt}
                        />
                      </li>
                    )
                  }
                )}
              </ul>
            </div>
            <div className="content-sidebar">
              <SidebarMain />
            </div>
          </div>
        </div>
      </section>
    </LayoutMain>
  )
}

export const CategoryBySlug = graphql`
  query Category($categorySlug: String!) {
    allFile(
      filter: {
        sourceInstanceName: { eq: "articles" }
        childMdx: { frontmatter: { categorySlug: { eq: $categorySlug } } }
      }
    ) {
      nodes {
        id
        childMdx {
          frontmatter {
            category
            title
            date
            slug
            status
            author
            bannerCaption
            keywords
            categorySlug
            banner {
              id
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          excerpt
        }
      }
    }
  }
`

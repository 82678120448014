import React from 'react'
import { Link } from 'gatsby'

import { useArticles } from '../../hooks/use-articles';

const LatestArticles = () => {
  const posts = useArticles();

  if (posts && posts.length > 1) {
    return (
      <ul className="latest-articles">
  {
          posts.slice(0, 3).map(({ node: { id, childMdx: { frontmatter } } }) => {
            return (
              <li className="recent-articles-item" key={id}>
                <Link to={`/articles/${frontmatter.slug}`}>{frontmatter.title}</Link>
              </li>
            )
          })
        }
      </ul>
    )
  } else {
    return 'no posts'
  }
}

export default LatestArticles
